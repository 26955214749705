<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Editing {{ serviceForm.stringID }} - {{ serviceForm.customerName }}
                </h1>
                <p class="log_info">
                  Created by {{ serviceForm.createdBy ? serviceForm.createdBy.name : '' }} on {{ dateFormatWithTime(serviceForm.createdAt) }}<br>Last updated on {{ dateFormatWithTime(serviceForm.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitSaveFormConfirmation">
      <validation-observer
        ref="serviceFormEditForm"
      >
        <b-card
          v-show="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')"
          ref="applicant_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Applicant Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Customer Name*"
                label-for="h-booking-customerName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer Name"
                  vid="customerName"
                  rules="required"
                >
                  <b-form-input
                    id="h-booking-customerName"
                    v-model="customerName"
                    placeholder="First name and last name"
                    :state="(errors.length > 0 || customerNameValidation) ? false : null"
                    name="customerName"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-applicant', 'ServiceForm')"
                    @input="generateTitle(null)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerNameValidation"
                    class="text-danger"
                  >
                    {{ customerNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Mobile Number"
                label-for="h-booking-customerContact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Number"
                  vid="customerContact"
                  :rules="customerEmail === '' ? 'required|digits:8' : 'digits:8'"
                >
                  <b-form-input
                    id="h-booking-customerContact"
                    v-model="customerContact"
                    placeholder="(65) Enter 8-digits number"
                    :state="(errors.length > 0 || customerContactValidation) ? false : null"
                    name="customerContact"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-applicant', 'ServiceForm')"
                    @input="customerContactValidation == true ? customerContactValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerContactValidation"
                    class="text-danger"
                  >
                    {{ customerContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Email"
                label-for="h-booking-customerEmail"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="customerEmail"
                  :rules="customerContact === '' ? 'required|email' : 'email'"
                >
                  <b-form-input
                    id="h-booking-customerEmail"
                    v-model="customerEmail"
                    placeholder="email@domain.com"
                    :state="(errors.length > 0 || customerEmailValidation) ? false : null"
                    name="customerEmail"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-applicant', 'ServiceForm')"
                    @input="customerEmailValidation == true ? customerEmailValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerEmailValidation"
                    class="text-danger"
                  >
                    {{ customerEmailError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-card
          ref="deceased_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Particulars of Deceased
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Niche No."
                label-for="h-booking-deceasedNiche"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Niche No."
                  vid="deceasedNiche"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-deceasedNiche"
                    v-model="deceasedNiche"
                    placeholder="e.g. A-S5A-AA-01-002"
                    :state="(errors.length > 0 || deceasedNicheValidation) ? false : null"
                    name="deceasedNiche"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    @input="generateTitle(null); deceasedNicheValidation == true ? deceasedNicheValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="deceasedNicheValidation"
                    class="text-danger"
                  >
                    {{ deceasedNicheError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Pedestal No."
                label-for="h-booking-deceasedPedestal"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pedestal No."
                  vid="deceasedPedestal"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-deceasedPedestal"
                    v-model="deceasedPedestal"
                    placeholder="e.g. A-S5A-AA-01-002"
                    :state="(errors.length > 0 || deceasedPedestalValidation) ? false : null"
                    name="deceasedPedestal"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    @input="generateTitle(null); deceasedPedestalValidation == true ? deceasedPedestalValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="deceasedPedestalValidation"
                    class="text-danger"
                  >
                    {{ deceasedPedestalError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Anling Lot No."
                label-for="h-booking-deceasedAngling"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Anling Lot No."
                  vid="deceasedAngling"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-deceasedAngling"
                    v-model="deceasedAngling"
                    placeholder="e.g. A-S5A-AA-01-002"
                    :state="(errors.length > 0 || deceasedAnglingValidation) ? false : null"
                    name="deceasedAngling"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    @input="generateTitle(null)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="deceasedAnglingValidation"
                    class="text-danger"
                  >
                    {{ deceasedAnglingError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Name of Deceased"
                label-for="h-booking-deceasedName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name of Deceased"
                  vid="deceasedName"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-deceasedName"
                    v-model="deceasedName"
                    placeholder="Name of Deceased"
                    :state="(errors.length > 0 || deceasedNameValidation) ? false : null"
                    name="deceasedName"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    @input="generateTitle(null); deceasedNameValidation == true ? deceasedNameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="deceasedNameValidation"
                    class="text-danger"
                  >
                    {{ deceasedNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Age"
                label-for="h-booking-deceasedAge"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Age"
                  vid="deceasedAge"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-deceasedAge"
                    v-model="deceasedAge"
                    placeholder="Age"
                    :state="(errors.length > 0 || deceasedAgeValidation) ? false : null"
                    name="deceasedAge"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    @input="deceasedAgeValidation == true ? deceasedAgeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="deceasedAgeValidation"
                    class="text-danger"
                  >
                    {{ deceasedAgeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="md-6"
            >
              <b-form-group
                label="Departed Date"
                label-for="h-event-departedDate"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-departedDate"
                    v-model="departedDate"
                    class="form-control flat-pickr-group"
                    placeholder="Departed Date"
                    :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                    :config="flatPickrConfig"
                    @input="departedDateValidation == true ? departedDateValidation = false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="departedDateValidation"
                  class="text-danger"
                >
                  {{ departedDateError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
            >
              <b-form-group
                label="Gender"
                label-for="deceasedGender"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Gender"
                  rules=""
                >
                  <b-form-radio-group
                    id="deceasedGender"
                    v-model="deceasedGender"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio"
                    :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                  >
                    <b-form-radio
                      v-for="(option, index) in genderOptions"
                      :key="index"
                      :value="option.value"
                      class="mb-50"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <feather-icon
                        v-if="deceasedGender === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <br>

                  <small
                    v-if="deceasedGenderValidation"
                    class="text-danger"
                  >
                    {{ deceasedGenderError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="repeatCard"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Service Requests
            </h3>
          </template>
          <b-card
            v-for="(item, index) in serviceRequests"
            :id="'serviceRequests' + index"
            :key="index"
            ref="row"
            class="serviceRequests_fields sf-new shadow-body new_design_requests"
          >
            <template #header>
              <div class="d-flex hover-td">
                <h3 class="align-middle mr-auto sd-title">
                  {{ item.title ? item.title : `Service Request #${index + 1}` }}
                </h3>
                <b-button
                  variant="flat-primary"
                  class="edit-icon primary-button-with-background no-background"
                  type="button"
                  style="padding: 0rem 0.5rem;"
                  @click="editServiceRequestTitle(index)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="ml-1"
                    style="vertical-align: text-top;"
                  />
                </b-button>
                <b-button
                  variant="flat-primary"
                  class="edit-icon primary-button-with-background no-background"
                  type="button"
                  style="padding: 0rem 0.5rem;"
                  @click="removeServiceRequest(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-25"
                    style="vertical-align: text-top;"
                  />
                </b-button>
              </div>
            </template>
            <b-row>
              <b-col md="3">
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Type*"
                    :label-for="'type' + index"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <v-select
                      :id="'type' + index"
                      v-model="item.workFlow"
                      label="name"
                      placeholder="Select an option"
                      :options="workFlowOptions"
                      :reduce="name => name._id"
                      :selectable="option => !option.hasOwnProperty('category')"
                      :clearable="false"
                      :disabled="!item.new || !canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-deceased', 'ServiceForm')"
                      @input="generateTitle(index)"
                    >
                      <template #option="{ category, name, }">
                        <div
                          v-if="category"
                          class="group"
                        >
                          {{ category }}
                        </div>
                        {{ name }}
                      </template>
                    </v-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Cost -->
              <b-col md="3">
                <b-form-group
                  label="Location*"
                  :label-for="'location' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Location"
                    vid="location"
                    rules="required"
                  >
                    <b-form-input
                      :id="'location' + index"
                      v-model="item.location"
                      placeholder="Location"
                      :state="(errors.length > 0) ? false : null"
                      name="remarks"
                      :readonly="!item.new && (!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest'))"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Quantity -->
              <b-col md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  vid="date"
                  rules="required"
                >
                  <b-form-group
                    label="Date*"
                    :label-for="'date' + index"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-input-group>
                      <flat-pickr
                        :id="'date' + index"
                        v-model="item.date"
                        class="form-control flat-pickr-group"
                        placeholder="Select the Date"
                        :config="flatPickrConfigWithoutTime"
                        :disabled="!item.new && (!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest'))"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="CalendarIcon"
                          class="cursor-pointer"
                          data-toggle
                          size="18"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Time"
                  vid="time"
                  rules="required"
                >
                  <b-form-group
                    label="Time*"
                    :label-for="'time' + index"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-input-group>
                      <flat-pickr
                        :id="'time' + index"
                        v-model="item.time"
                        class="form-control flat-pickr-group"
                        placeholder="Select the Time"
                        :config="flatPickrConfigTime"
                        :disabled="!item.new && (!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest'))"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="ClockIcon"
                          class="cursor-pointer"
                          data-toggle
                          size="18"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Related File No."
                  :label-for="'fileNo' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Related File No."
                    vid="fileNo"
                    rules=""
                  >
                    <b-form-input
                      :id="'fileNo' + index"
                      v-model="item.fileNo"
                      placeholder="Related File No."
                      :state="(errors.length > 0) ? false : null"
                      name="fileNo"
                      :readonly="!item.new && (!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest'))"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="9">
                <b-form-group
                  label="Remarks"
                  :label-for="'remarks' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                    rules=""
                  >
                    <b-form-input
                      :id="'remarks' + index"
                      v-model="item.remarks"
                      placeholder="Remarks"
                      :state="(errors.length > 0) ? false : null"
                      name="remarks"
                      :readonly="!item.new && (!canViewThisAction('update', 'ServiceRequest') && !canViewThisAction('update-detail', 'ServiceRequest'))"
                      @input="generateTitle(index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <template #footer>
              <div>
                <div class="d-flex justify-content-between">
                  <span
                    class="my-auto"
                    style="font-weight: 500; font-size: 15px;"
                  >
                    Service descriptions
                  </span>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    type="button"
                    class="pr-0 no-background"
                    @click="editServiceDescription(index)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class=""
                      size="16"
                    />
                  </b-button>
                </div>
                <div
                  v-if="item.showServiceDescription == true"
                  class="d-flex sf-service-desc-div"
                >
                  <div
                    v-for="(opt, key) in trimServiceDescription(item.serviceDescription)"
                    :key="key"
                  >
                    <div
                      v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value)"
                      class="sf-service-desc"
                    >
                      <span
                        v-if="Array.isArray(opt.value)"
                      >
                        <span
                          v-for="(option, idx) in opt.value"
                          :key="idx"
                        >
                          {{ option }}
                          <feather-icon
                            :id="'unique_'+index+key+idx"
                            icon="AlertCircleIcon"
                            class="ml-50 text-primary"
                            size="14"
                          />
                          <b-tooltip
                            placement="auto"
                            :target="'unique_'+index+key+idx"
                            :title="opt.key"
                          />
                        </span>
                      </span>
                      <span v-else-if="opt.value">
                        <span
                          v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                        >
                          {{ opt.key }} <span class="green-text"><span v-if="opt.type == 'increment'">x</span>{{ opt.value }}</span>
                        </span>
                        <span v-else>{{ opt.key }} {{ opt.value }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-between">
                  <span
                    class="my-auto"
                    style="font-weight: 500; font-size: 15px;"
                  >
                    Payment Details
                  </span>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    type="button"
                    class="pr-0 no-background"
                    @click="editSRPayment(index)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class=""
                      size="16"
                    />
                  </b-button>
                </div>
                <div
                  v-if="item.paymentStatus"
                  class="d-flex sf-service-desc-div"
                >
                  <!-- <span class="sf-service-desc">
                    {{ item.paymentStatus }}
                    <feather-icon
                      :id="'unique_sr+p'+index"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_sr+p'+index"
                      title="Payment Status"
                    />
                  </span> -->
                  <b-badge
                    :variant="`light-${resolveServiceRequestPaymentStatusVariant(item.paymentStatus)}`"
                    class="text-capitalize"
                  >
                    {{ item.paymentStatus }}
                  </b-badge>
                  {{ item.paymentRemarks }}
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>
          </b-card>
          <div class="add_item">
            <b-button
              v-if="canViewThisAction('create', 'ServiceRequest')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addServiceRequest"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Item</span>
            </b-button>
          </div>
        </b-card>

        <b-card
          v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-payment', 'ServiceForm')"
          ref="payment_details"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Payment Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Payment Status"
                vid="paymentStatus"
                rules="required"
              >
                <b-form-group
                  label="Payment Status*"
                  label-for="h-bookings-paymentStatus"
                  label-cols-md="4"
                  :state="(errors.length > 0 || paymentStatusValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-paymentStatus"
                    v-model="paymentStatus"
                    label="title"
                    placeholder="Select from list"
                    :options="paymentStatusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="paymentStatusValidation == true ? paymentStatusValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentStatusValidation"
                    class="text-danger"
                  >
                    {{ paymentStatusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <b-form-group
                label="Payment Remarks"
                label-for="h-booking-paymentRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Remarks"
                  vid="paymentRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-paymentRemarks"
                    v-model="paymentRemarks"
                    placeholder="Payment Remarks"
                    rows="3"
                    name="paymentRemarks"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-payment', 'ServiceForm')"
                    @input="paymentRemarksValidation == true ? paymentRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentRemarksValidation"
                    class="text-danger"
                  >
                    {{ paymentRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="agent_detail"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Agent Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Agent Name"
                label-for="h-booking-agentName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Name"
                  vid="agentName"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-agentName"
                    v-model="agentName"
                    placeholder="Agent Name"
                    :state="(errors.length > 0 || agentNameValidation) ? false : null"
                    name="agentName"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-agent', 'ServiceForm')"
                    @input="agentNameValidation == true ? agentNameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentNameValidation"
                    class="text-danger"
                  >
                    {{ agentNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Agent Code"
                label-for="h-booking-agentCode"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Code"
                  vid="agentCode"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-agentCode"
                    v-model="agentCode"
                    placeholder="Agent Code"
                    :state="(errors.length > 0 || agentCodeValidation) ? false : null"
                    name="agentCode"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-agent', 'ServiceForm')"
                    @input="agentCodeValidation == true ? agentCodeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentCodeValidation"
                    class="text-danger"
                  >
                    {{ agentCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Agent Mobile Number"
                label-for="h-booking-agentContact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Mobile Number"
                  vid="agentContact"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-agentContact"
                    v-model="agentContact"
                    placeholder="Agent Mobile Number"
                    :state="(errors.length > 0 || agentContactValidation) ? false : null"
                    name="agentContact"
                    :readonly="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-agent', 'ServiceForm')"
                    @input="agentContactValidation == true ? agentContactValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentContactValidation"
                    class="text-danger"
                  >
                    {{ agentContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-card
          ref="attachment"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Attachments
            </h3>
          </template>
          <b-row>
            <b-col
              cols="12"
              :class="{'disable_dropzone': (!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-attachment', 'ServiceForm')) }"
            >
              <vue-dropzone
                id="event-images"
                ref="myVueDropzone"
                disabled
                :options="dropzoneOptions"
                :use-custom-slot="true"
                @vdropzone-files-added="processFile"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-error="handleError"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Drop file here or click to upload
                  </h3>
                  <div class="subtitle">
                    Images should not bigger than 5 mb. JPEG or PDF only.
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="mt-2"
                  >
                    Upload file
                  </b-button>
                </div>
              </vue-dropzone>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Call Logs
            </h3>
          </template>
          <b-row v-if="showCallLogFields">
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Type of Call"
                vid="typeOfCall"
                rules=""
              >
                <b-form-group
                  label="Type of Call*"
                  label-for="h-bookings-typeOfCall"
                  label-cols-md="4"
                  :state="(errors.length > 0 || typeOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-typeOfCall"
                    v-model="typeOfCall"
                    label="name"
                    placeholder="Select an option"
                    :options="callLogTypes"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="setNatureOfCalls"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeOfCallValidation"
                    class="text-danger"
                  >
                    {{ typeOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Nature of Call"
                vid="natureOfCall"
                rules=""
              >
                <b-form-group
                  label="Nature of Call*"
                  label-for="h-bookings-natureOfCall"
                  label-cols-md="4"
                  :state="(errors.length > 0 || natureOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-natureOfCall"
                    v-model="natureOfCall"
                    label="title"
                    placeholder="Nature of this call"
                    :options="callNatures"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="natureOfCallValidation == true ? natureOfCallValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="natureOfCallValidation"
                    class="text-danger"
                  >
                    {{ natureOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="12">
              <b-form-group
                label="Details and Remarks"
                label-for="h-booking-callRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details and Remarks"
                  vid="callRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-callRemarks"
                    v-model="callRemarks"
                    placeholder="Details and Remarks"
                    rows="3"
                    name="callRemarks"
                    @input="callRemarksValidation == true ? callRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="callRemarksValidation"
                    class="text-danger"
                  >
                    {{ callRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="showCallLogFields = true"
                >
                  <span class="align-middle">Add a call log</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          ref="other_details"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Other Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Duty Officer"
                vid="dutyOfficer"
                rules="required"
              >
                <b-form-group
                  label="Duty Officer*"
                  label-for="h-bookings-dutyOfficer"
                  label-cols-md="4"
                  :state="(errors.length > 0 || dutyOfficerValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-dutyOfficer"
                    v-model="dutyOfficer"
                    label="name"
                    placeholder="Select an option"
                    :options="dutyOfficerOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                    :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-duty-officer', 'ServiceForm')"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dutyOfficerValidation"
                    class="text-danger"
                  >
                    {{ dutyOfficerError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-bookings-status"
                  label-cols-md="4"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    :disabled="!canViewThisAction('update', 'ServiceForm') && !canViewThisAction('update-status', 'ServiceForm')"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" />
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-service-forms-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <b-modal
      id="modal-update-title"
      ref="update-title-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      size="lg"
      no-close-on-backdrop
      title="Update Title"
      @ok="updateTitleInRequest"
    >
      <b-form @submit.prevent="updateTitleInRequest">
        <validation-observer
          ref="titleForm"
        >
          <b-form-group
            label="Title*"
            label-for="h-updateTitle"
          >
            <validation-provider
              #default="{ errors }"
              name="Title"
              vid="updateTitle"
              rules="required"
            >
              <b-form-textarea
                id="h-updateTitle"
                v-model="updateTitle"
                placeholder="Title"
                :state="(errors.length > 0) ? false : null"
                name="updateTitle"
                class="mb-0"
                rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-sr-payment"
      ref="update-sr-payment-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      size="md"
      no-close-on-backdrop
      title="Update Payment Details"
      @ok="updatePaymentInRequest"
    >
      <b-form @submit.prevent="updatePaymentInRequest">
        <validation-observer
          ref="paymentForm"
        >
          <validation-provider
            #default="{ errors }"
            name="Payment Status"
            vid="paymentStatus"
            rules="required"
          >
            <b-form-group
              label="Payment Status*"
              label-for="h-bookings-srPaymentStatus"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="h-bookings-srPaymentStatus"
                v-model="srPaymentStatus"
                label="title"
                placeholder="Select from list"
                :options="paymentStatusOptions"
                :reduce="title => title.code"
                :clearable="false"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Payment Remarks"
            label-for="h-srPaymentRemarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment Remarks"
              vid="srPaymentRemarks"
              rules=""
            >
              <b-form-textarea
                id="h-srPaymentRemarks"
                v-model="srPaymentRemarks"
                placeholder="Payment Remarks"
                :state="(errors.length > 0) ? false : null"
                name="srPaymentRemarks"
                class="mb-0"
                rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-sd"
      ref="update-sd-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Add to Service Request"
      cancel-title="Cancel"
      modal-class="status-update-modal"
      centered
      no-close-on-backdrop
      size="lg"
      title="Service Descriptions"
      @ok="addServiceDescription"
    >
      <b-form @submit.prevent="addServiceDescription">
        <validation-observer
          ref="sdForm"
        >
          <b-row
            v-for="(opt, key) in customFields"
            :key="key"
          >
            <b-col cols="12">
              <div
                v-if="opt.type == 'title-and-text'"
                class="mb-1"
              >
                <span class="text-bold-black">
                  {{ opt.label }}
                </span>
                <br>
                <span>
                  {{ serviceDescription[key].text }}
                </span>
              </div>
              <b-form-group
                v-if="opt.type == 'short-answer'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-input
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    class="mb-0"
                    :placeholder="opt.label"
                    :state="(errors.length > 0) ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'long-answer'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-textarea
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    trim
                    class="mb-0"
                    :placeholder="opt.label"
                    :state="(errors.length > 0) ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'increment'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-spinbutton
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    inline
                    class="mb-0 spin-button-sd"
                    :state="(errors.length > 0) ? false : null"
                    :step="opt.extraOptions[2].value"
                    :min="opt.extraOptions[0].value"
                    :max="opt.extraOptions[1].value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                label-cols-md="4"
                class="mb-0"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >

                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio low-padding display-block-checkbox"
                  >
                    <b-form-checkbox
                      v-for="(optn, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="optn.key"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="serviceDescription[key].value.includes(optn.key)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ optn.key }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="mb-0"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio low-padding display-block-checkbox"
                    @input="makeCheckboxToRadio(key)"
                  >
                    <b-form-checkbox
                      v-for="(optn, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="optn.key"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="serviceDescription[key].value.includes(optn.key)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ optn.key }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'single-select'"
                label-cols-md="4"
                class="mb-0"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >

                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio low-padding display-block-checkbox"
                    @input="makeCheckboxToRadio(key)"
                  >
                    <b-form-checkbox
                      v-for="(optn, kindex) in requiredOptions"
                      :key="kindex"
                      :value="optn.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="serviceDescription[key].value.includes(optn.value)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ optn.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <validation-provider
                v-if="opt.type == 'dropdown'"
                #default="{ errors }"
                :name="opt.label"
                :vid="'event-custom-field-' + key"
                :rules="opt.required == 'yes' ? 'required' : ''"
              >
                <b-form-group
                  label-cols-md="4"
                  class="mb-0"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    :id="'event-custom-field-' + key"
                    v-model="serviceDescription[key].value"
                    label="value"
                    placeholder="Select From List"
                    :options="opt.extraOptions"
                    :reduce="value => value.key"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <hr v-if="(!opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.id) || (opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.parent)">
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>Service Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea, BForm, BButton, BNav, BTab, BTabs,
  BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BFormCheckbox, BFormInvalidFeedback, BFormSpinbutton, BTooltip, VBTooltip, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContentCustom from '../ToastificationContentCustom.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormSpinbutton,
    BTooltip,
    BTab,
    BTabs,
    BBadge,

    vSelect,
    flatPickr,
    quillEditor,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  mixins: [heightTransition],
  data() {
    return {
      canViewThisAction,
      savedData: false,
      serviceForm: {},
      customerName: '',
      customerContact: '',
      customerEmail: '',
      deceasedName: '',
      deceasedAge: '',
      deceasedGender: '',
      deceasedNiche: '',
      deceasedPedestal: '',
      deceasedAngling: '',
      departedDate: '',
      serviceRequests: [],
      serviceRequestsOld: [],
      serviceDescription: [],
      updateTitle: '',
      selectedIndex: 0,
      serviceDescriptionMaster: [],
      paymentRemarks: '',
      agentName: '',
      agentCode: '',
      agentContact: '',
      attachments: [],
      dutyOfficer: '',
      typeOfCall: '',
      natureOfCall: '',
      callRemarks: '',
      showCallLogFields: false,
      status: 'pending',
      paymentStatus: 'N/A',
      paymentStatusError: '',
      paymentStatusValidation: false,
      customerNameError: 'Valid name is required',
      customerNameValidation: false,
      customerContactError: 'Valid customer contact is required',
      customerContactValidation: false,
      customerEmailError: 'Valid email is required',
      customerEmailValidation: false,
      deceasedNameError: 'Valid name is required',
      deceasedNameValidation: false,
      deceasedAgeError: 'Valid name is required',
      deceasedAgeValidation: false,
      deceasedGenderError: 'Valid name is required',
      deceasedGenderValidation: false,
      deceasedNicheError: 'Valid name is required',
      deceasedNicheValidation: false,
      deceasedPedestalError: 'Valid name is required',
      deceasedPedestalValidation: false,
      deceasedAnglingError: 'Valid name is required',
      deceasedAnglingValidation: false,
      departedDateError: 'Valid name is required',
      departedDateValidation: false,
      paymentRemarksError: 'Valid value is required',
      paymentRemarksValidation: false,
      agentNameError: 'Valid value is required',
      agentNameValidation: false,
      agentCodeError: 'Valid value is required',
      agentCodeValidation: false,
      agentContactError: 'Valid value is required',
      agentContactValidation: false,
      dutyOfficerError: 'Valid value is required',
      dutyOfficerValidation: false,
      statusError: 'Valid value is required',
      statusValidation: false,
      typeOfCallError: 'Valid value is required',
      typeOfCallValidation: false,
      natureOfCallError: 'Valid value is required',
      natureOfCallValidation: false,
      callRemarksError: 'Valid value is required',
      callRemarksValidation: false,
      dutyOfficerOptions: [],
      customFields: [],
      workFlowCats: [],
      previousValues: [],
      previousServiceRequest: [],
      callLogTypes: [],
      callNatures: [],
      statusOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Completed and Paid', code: 'completed and paid' },
        { title: 'Pending', code: 'pending' },
      ],
      srPaymentStatus: 'N/A',
      srPaymentRemarks: '',
      paymentStatusOptions: [
        { title: 'Paid', code: 'Paid' },
        { title: 'Partially Paid', code: 'Partially Paid' },
        { title: 'Unpaid', code: 'Unpaid' },
        { title: 'FSP', code: 'FSP' },
        { title: 'N/A', code: 'N/A' },
      ],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Other', value: 'other' },
      ],
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      flatPickrConfigWithTime: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', disableMobile: true,
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      workFlowOptions: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      venueField: '',
      titleFields: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      tabActive: 'email',
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      removedRequest: [],

      // validation rules
      required,
    }
  },
  // computed: {
  //   changeData() {
  //     return [this.customerName, this.customerContact, this.serviceRequests]
  //   },
  // },
  // watch: {
  //   changeData: {
  //     handler(val) {
  //       console.log('value change: ', val)
  //     },
  //     deep: true,
  //   },
  // },
  // updated() {
  //   this.initTrHeight()
  // },

  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.$http.get(`operation/service-forms/${this.$route.params.id}`, { params: { department: true } })
      .then(response => {
        const sf = response.data.data
        this.serviceForm = sf
        this.customerMobileVal = sf.customerContact
        this.customerEmailVal = sf.customerEmail
        this.customerName = sf.customerName || ''
        this.customerContact = sf.customerContact || ''
        this.customerEmail = sf.customerEmail || ''
        this.deceasedName = sf.deceasedName || ''
        this.deceasedAge = sf.deceasedAge || ''
        this.deceasedGender = sf.deceasedGender || ''
        this.deceasedNiche = sf.deceasedNiche || ''
        this.deceasedPedestal = sf.deceasedPedestal || ''
        this.deceasedAngling = sf.deceasedAngling || ''
        this.departedDate = sf.departedDate ? this.dateFormat(sf.departedDate) : ''
        this.dutyOfficer = sf.dutyOfficer || ''
        this.status = sf.status || ''
        this.paymentRemarks = sf.paymentRemarks || ''
        this.paymentStatus = sf.paymentStatus || 'N/A'
        this.agentName = sf.agentName || ''
        this.agentCode = sf.agentCode || ''
        this.agentContact = sf.agentContact || ''
        // this.previousValues = sf.serviceDescription || []

        this.previousServiceRequest = response.data.serviceRequests || []
        this.venueField = response.data.venueField ?? ''
        this.titleFields = response.data.titleFields ?? ''

        document.title = `Editing ${sf.stringID} - ${sf.customerName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

        sf.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })

        const { serviceDescription } = response.data
        if (serviceDescription) {
          if (serviceDescription.customFields.length) {
            const newArray = []
            serviceDescription.customFields.forEach(arrayItem => {
              const newObj = {}
              newObj.key = arrayItem.label
              newObj.type = arrayItem.type
              newObj.id = arrayItem.id
              newObj.parent = arrayItem.parent
              if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                newObj.value = []
              } else if (arrayItem.type === 'title-and-text') {
                newObj.value = ''
                newObj.text = arrayItem.description
              } else if (arrayItem.type === 'increment') {
                newObj.value = +arrayItem.extraOptions[0].value
              } else {
                newObj.value = ''
              }
              newArray.push(newObj)
            })

            this.serviceDescription = newArray
            this.serviceDescriptionMaster = newArray
          }

          this.customFields = serviceDescription.customFields || []
        }

        this.previousServiceRequest.forEach(arrayItem => {
          const obj = {
            title: arrayItem.updatedTitle || arrayItem.title,
            workFlow: arrayItem.workFlow,
            location: arrayItem.location,
            paymentStatus: arrayItem.paymentStatus || 'N/A',
            paymentRemarks: arrayItem.paymentRemarks || '',
            date: `${this.dateFormat(arrayItem.date)}`,
            time: `${this.twentyFourHourTimeFormatFromDateString(arrayItem.date)}`,
            remarks: arrayItem.remarks,
            fileNo: arrayItem.fileNo,
            disabled: true,
            serviceDescription: this.generateServiceDescription(arrayItem.serviceDescription),
            showServiceDescription: arrayItem.showServiceDescription,
            serviceDescriptionValues: arrayItem.serviceDescriptionValues,
            serviceDescriptionTitle: this.generateServiceDescriptionTitle(arrayItem.serviceDescription),
            _id: arrayItem._id,
          }
          this.serviceRequests.push(JSON.parse(JSON.stringify(obj)))
          this.serviceRequestsOld.push(JSON.parse(JSON.stringify(obj)))
        })

        this.dutyOfficerOptions = response.data.dutyOfficers || []
        this.callLogTypes = response.data.callLogTypes || []
        this.workFlowCats = response.data.workFlowCats || []

        this.workFlowCats.forEach(element => {
          this.workFlowOptions.push({
            category: element.name,
            name: null,

            _id: element._id,
          })
          element.workFlows.forEach(flow => {
            this.workFlowOptions.push({
              name: flow.name,

              _id: flow._id,
            })
          })
        })

        const { section } = this.$route.query
        if (section) {
          const element = this.$refs[section]
          if (element) {
            const top = element.offsetTop - 135
            window.scrollTo({
              top,
              behavior: 'smooth',
            })
          }
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    // this.$http.get('operation/service-forms/respond-with/duty-officers-workflow/on-edit')
    //   .then(response => {
    //     this.dutyOfficerOptions = response.data.dutyOfficers || []
    //     this.workFlowCats = response.data.workFlowCats || []
    //     const { serviceDescription } = response.data

    //     this.workFlowCats.forEach(element => {
    //       this.workFlowOptions.push({
    //         category: element.name,
    //         name: null,
    //         _id: null,
    //       })
    //       element.workFlows.forEach(flow => {
    //         this.workFlowOptions.push({
    //           name: flow.name,
    //
    //           _id: flow._id,
    //         })
    //       })
    //     })

    //     if (serviceDescription) {
    //       if (serviceDescription.customFields.length) {
    //         const newArray = []
    //         serviceDescription.customFields.forEach(arrayItem => {
    //           const newObj = {}
    //           newObj.key = arrayItem.label
    //           const exist = this.previousValues.find(o => o.key === arrayItem.label)
    //           if (exist) {
    //             if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
    //               if (Array.isArray(exist.value)) {
    //                 newObj.value = exist.value || []
    //               } else {
    //                 newObj.value = [exist.value] || []
    //               }
    //             } else {
    //               newObj.value = exist.value || ''
    //             }
    //           } else {
    //             // eslint-disable-next-line no-lonely-if
    //             if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
    //               newObj.value = []
    //             } else {
    //               newObj.value = ''
    //             }
    //           }
    //           newArray.push(newObj)
    //         })

    //         this.serviceDescription = newArray
    //       }

    //       this.customFields = serviceDescription.customFields || []
    //     }
    //   })
    //   .catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  },

  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    trimServiceDescription(data) {
      return data.filter(obj => (Array.isArray(obj.value) && obj.value.length) || (!Array.isArray(obj.value) && obj.value))
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-service-forms-show', params: { id: this.$route.params.id } })
          }
        })
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    setNatureOfCalls() {
      this.callNatures = []

      const log = this.callLogTypes.find(o => o._id === this.typeOfCall)
      if (log) {
        log.callNatures.forEach(element => {
          const obj = {}
          obj.title = element
          obj.code = element
          this.callNatures.push(obj)
        })

        // this.natureOfCall = this.callNatures[0].code || ''
      }
    },
    addServiceRequest() {
      this.serviceRequests.push({
        title: '',
        workFlow: '',
        location: '',
        date: '',
        time: '',
        remarks: '',
        fileNo: '',
        paymentStatus: '',
        paymentRemarks: '',
        new: true,
        serviceDescription: [],
        showServiceDescription: false,
        serviceDescriptionValues: [],
        serviceDescriptionTitle: '',
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeServiceRequest(index) {
      const sr = this.serviceRequests[index]
      if (sr._id) {
        this.removedRequest.push(sr._id)
      }
      this.serviceRequests.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    editServiceRequestTitle(index) {
      this.selectedIndex = index
      this.updateTitle = this.serviceRequests[index].title
      this.$refs['update-title-modal'].toggle('#toggle-btn')
    },
    editSRPayment(index) {
      this.selectedIndex = index
      this.srPaymentRemarks = this.serviceRequests[index].paymentRemarks
      this.srPaymentStatus = this.serviceRequests[index].paymentStatus
      this.$refs['update-sr-payment-modal'].toggle('#toggle-btn')
    },
    editServiceDescription(index) {
      this.selectedIndex = index
      const parsedDescription = JSON.parse(JSON.stringify(this.serviceRequests[index].serviceDescription))
      this.serviceDescription = parsedDescription.length ? parsedDescription : JSON.parse(JSON.stringify(this.serviceDescriptionMaster))
      // console.log(this.serviceDescription)
      this.$refs['update-sd-modal'].toggle('#toggle-btn')
    },
    updateTitleInRequest(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.titleForm.validate().then(success => {
        if (success) {
          this.serviceRequests[this.selectedIndex].title = this.updateTitle
          this.$refs['update-title-modal'].toggle('#toggle-btn')
        }
      })
    },
    updatePaymentInRequest(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          this.serviceRequests[this.selectedIndex].paymentStatus = this.srPaymentStatus
          this.serviceRequests[this.selectedIndex].paymentRemarks = this.srPaymentRemarks
          this.$refs['update-sr-payment-modal'].toggle('#toggle-btn')
        }
      })
    },
    resolveServiceRequestPaymentStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Partially Paid') return 'warning'
      if (status === 'Unpaid') return 'danger'
      return 'secondary'
    },
    addServiceDescription(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sdForm.validate().then(success => {
        if (success) {
          this.serviceRequests[this.selectedIndex].serviceDescription = this.serviceDescription
          const serviceDescriptionValues = []
          let showServiceDescription = false
          let serviceDescriptionTitle = ''
          const titleArray = this.titleFields.split(',')
          this.serviceDescription.forEach(obj => {
            if (!obj.parent) {
              // sortedData.push(obj)
              const children = this.serviceDescription.filter(o => o.parent === obj.id)
              if (children.length) {
                const childrenArray = []
                children.forEach(opt => {
                  if (opt.type === 'title-and-text') {
                    const child = {
                      id: opt.id,
                      parent: opt.parent,
                      key: opt.key,
                      type: opt.type,
                      text: opt.text,
                      value: '',
                    }
                    childrenArray.push(child)
                  } else if (Array.isArray(opt.value)) {
                    if (opt.value.length) {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.value.join(', ')}`
                        } else {
                          serviceDescriptionTitle += `+${opt.value.join(', ')}`
                        }
                      }
                    }
                  } else if (opt.value) {
                    if (opt.type === 'increment' && Number(opt.value) !== 0) {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: `x${opt.value}`,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} x${opt.value}`
                        } else {
                          serviceDescriptionTitle += `+${opt.key} x${opt.value}`
                        }
                      }
                    } else {
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} ${opt.value}`
                        } else {
                          serviceDescriptionTitle += `+${opt.key} ${opt.value}`
                        }
                      }
                    }
                  }
                })
                const parent = {
                  id: obj.id,
                  parent: obj.parent,
                  key: obj.key,
                  type: obj.type,
                  text: obj.text,
                  value: obj.value,
                  children: childrenArray,
                }
                serviceDescriptionValues.push(parent)
                // console.log(serviceDescriptionTitle)
              } else {
                // eslint-disable-next-line no-lonely-if
                if (obj.type === 'title-and-text') {
                  const parent = {
                    id: obj.id,
                    parent: obj.parent,
                    key: obj.key,
                    type: obj.type,
                    text: obj.text,
                    value: '',
                  }
                  serviceDescriptionValues.push(parent)
                } else if (Array.isArray(obj.value)) {
                  if (obj.value.length) {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.value.join(', ')}`
                      } else {
                        serviceDescriptionTitle += `+${obj.value.join(', ')}`
                      }
                    }
                  }
                } else if (obj.value) {
                  if (obj.type === 'increment' && Number(obj.value) !== 0) {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: `x${obj.value}`,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} x${obj.value}`
                      } else {
                        serviceDescriptionTitle += `+${obj.key} x${obj.value}`
                      }
                    }
                  } else {
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} ${obj.value}`
                      } else {
                        serviceDescriptionTitle += `+${obj.key} ${obj.value}`
                      }
                    }
                  }
                }
              }
            }
          })
          this.serviceRequests[this.selectedIndex].showServiceDescription = showServiceDescription
          this.serviceRequests[this.selectedIndex].serviceDescriptionValues = serviceDescriptionValues
          this.serviceRequests[this.selectedIndex].serviceDescriptionTitle = serviceDescriptionTitle
          this.generateTitle(this.selectedIndex)
          this.$refs['update-sd-modal'].toggle('#toggle-btn')
        }
      })
    },
    generateServiceDescription(data) {
      const newArray = []
      this.customFields.forEach(arrayItem => {
        const newObj = {}
        newObj.key = arrayItem.label
        newObj.type = arrayItem.type
        newObj.id = arrayItem.id
        newObj.parent = arrayItem.parent
        const exist = data.find(o => o.id === arrayItem.id)
        if (exist) {
          if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
            if (Array.isArray(exist.value)) {
              newObj.value = exist.value || []
            } else {
              newObj.value = [exist.value] || []
            }
          } else if (arrayItem.type === 'title-and-text') {
            newObj.value = ''
            newObj.text = arrayItem.description
          } else if (arrayItem.type === 'increment') {
            newObj.value = +exist.value
          } else {
            newObj.value = exist.value || ''
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
            newObj.value = []
          } else if (arrayItem.type === 'title-and-text') {
            newObj.value = ''
            newObj.text = arrayItem.description
          } else if (arrayItem.type === 'increment') {
            newObj.value = +arrayItem.extraOptions[0].value
          } else {
            newObj.value = ''
          }
        }
        newArray.push(newObj)
      })

      return newArray
    },
    generateServiceDescriptionTitle(data) {
      let serviceDescriptionTitle = ''
      const titleArray = this.titleFields.split(',')
      data.forEach(obj => {
        if (!obj.parent) {
          // sortedData.push(obj)
          const children = data.filter(o => o.id && o.parent === obj.id)
          if (children.length) {
            children.forEach(opt => {
              if (Array.isArray(opt.value)) {
                if (opt.value.length) {
                  if (titleArray.includes(obj.key)) {
                    if (serviceDescriptionTitle === '') {
                      serviceDescriptionTitle += `${opt.value.join(', ')}`
                    } else {
                      serviceDescriptionTitle += `+${opt.value.join(', ')}`
                    }
                  }
                }
              } else if (opt.value) {
                if (opt.type === 'increment' && Number(opt.value) !== 0) {
                  if (titleArray.includes(obj.key)) {
                    if (serviceDescriptionTitle === '') {
                      serviceDescriptionTitle += `${opt.key} x${opt.value}`
                    } else {
                      serviceDescriptionTitle += `+${opt.key} x${opt.value}`
                    }
                  }
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (titleArray.includes(obj.key)) {
                    if (serviceDescriptionTitle === '') {
                      serviceDescriptionTitle += `${opt.key} ${opt.value}`
                    } else {
                      serviceDescriptionTitle += `+${opt.key} ${opt.value}`
                    }
                  }
                }
              }
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (Array.isArray(obj.value)) {
              if (obj.value.length) {
                if (titleArray.includes(obj.key)) {
                  if (serviceDescriptionTitle === '') {
                    serviceDescriptionTitle += `${obj.value.join(', ')}`
                  } else {
                    serviceDescriptionTitle += `+${obj.value.join(', ')}`
                  }
                }
              }
            } else if (obj.value) {
              if (obj.type === 'increment' && Number(obj.value) !== 0) {
                if (titleArray.includes(obj.key)) {
                  if (serviceDescriptionTitle === '') {
                    serviceDescriptionTitle += `${obj.key} x${obj.value}`
                  } else {
                    serviceDescriptionTitle += `+${obj.key} x${obj.value}`
                  }
                }
              } else {
                // eslint-disable-next-line no-lonely-if
                if (titleArray.includes(obj.key)) {
                  if (serviceDescriptionTitle === '') {
                    serviceDescriptionTitle += `${obj.key} ${obj.value}`
                  } else {
                    serviceDescriptionTitle += `+${obj.key} ${obj.value}`
                  }
                }
              }
            }
          }
        }
      })

      return serviceDescriptionTitle
    },
    generateTitle(index = null) {
      if (index !== null && index >= 0) {
        const workFlow = this.workFlowOptions.find(o => o._id === this.serviceRequests[index].workFlow)
        if (workFlow) {
          const title = `${workFlow.name}${this.deceasedNiche ? ` ${this.deceasedNiche}` : ''}${this.deceasedPedestal ? ` ${this.deceasedPedestal}` : ''}${this.deceasedAngling ? ` ${this.deceasedAngling}` : ''}${this.deceasedName ? ` ${this.deceasedName}` : ''} ${this.customerName.toUpperCase()}${this.serviceRequests[index].serviceDescriptionTitle ? ` ${this.serviceRequests[index].serviceDescriptionTitle}` : ''}${this.serviceRequests[index].remarks ? ` ${this.serviceRequests[index].remarks}` : ''}`
          this.serviceRequests[index].title = title
        }
      } else {
        this.serviceRequests.forEach((req, key) => {
          const workFlow = this.workFlowOptions.find(o => o._id === req.workFlow)
          if (workFlow) {
            const title = `${workFlow.name}${this.deceasedNiche ? ` ${this.deceasedNiche}` : ''}${this.deceasedPedestal ? ` ${this.deceasedPedestal}` : ''}${this.deceasedAngling ? ` ${this.deceasedAngling}` : ''}${this.deceasedName ? ` ${this.deceasedName}` : ''} ${this.customerName.toUpperCase()}${this.serviceRequests[key].serviceDescriptionTitle ? ` ${this.serviceRequests[key].serviceDescriptionTitle}` : ''}${this.serviceRequests[key].remarks ? ` ${this.serviceRequests[key].remarks}` : ''}`
            this.serviceRequests[key].title = title
          }
        })
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.service_requests.scrollHeight)
      })
    },
    makeCheckboxToRadio(key) {
      const lastSelected = this.serviceDescription[key].value.slice(-1)[0]
      if (lastSelected) {
        this.serviceDescription[key].value = this.serviceDescription[key].value.filter(code => code === lastSelected)
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', 'Service Form')
      formData.append('formStatus', this.serviceForm.formStatus)
      formData.append('url', `${window.location.origin}/operations/service-forms/${this.$route.params.id}/form/pdf-view`)

      this.$http.post(`operation/service-forms/${this.$route.params.id}/form/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            this.savedData = true
            this.$router.push({ name: 'operation-service-forms-show', params: { id: response.data.data._id } })
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
    },
    submitSaveFormConfirmation() {
      let formEdited = false
      if (this.customerName !== this.serviceForm.customerName
      || this.customerContact !== this.serviceForm.customerContact
      || this.customerEmail !== this.serviceForm.customerEmail
      || this.deceasedName !== this.serviceForm.deceasedName
      || this.deceasedAge !== this.serviceForm.deceasedAge
      || this.deceasedGender !== this.serviceForm.deceasedGender
      || this.deceasedNiche !== this.serviceForm.deceasedNiche
      || this.deceasedPedestal !== this.serviceForm.deceasedPedestal
      || this.deceasedAngling !== this.serviceForm.deceasedAngling
      || this.departedDate !== (this.serviceForm.departedDate ? this.dateFormat(this.serviceForm.departedDate) : '')
      || this.agentName !== this.serviceForm.agentName
      || this.agentCode !== this.serviceForm.agentCode
      || this.agentContact !== this.serviceForm.agentContact) {
        formEdited = true
      }
      const objectsEqual = (o1, o2) => (typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p] || '', o2[p] || ''))
        : o1 === o2)

      const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
      if (!arraysEqual(this.serviceRequests, this.serviceRequestsOld)) {
        formEdited = true
      }
      if (formEdited) {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitForm()
      }
    },
    submitForm(option = null, submit = false) {
      this.$refs.serviceFormEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('customerName', this.customerName)
          formData.append('customerContact', this.customerContact)
          formData.append('customerEmail', this.customerEmail)
          formData.append('deceasedName', this.deceasedName)
          formData.append('deceasedAge', this.deceasedAge)
          formData.append('deceasedGender', this.deceasedGender)
          formData.append('deceasedNiche', this.deceasedNiche)
          formData.append('deceasedPedestal', this.deceasedPedestal)
          formData.append('deceasedAngling', this.deceasedAngling)
          formData.append('departedDate', this.departedDate)
          formData.append('paymentRemarks', this.paymentRemarks)
          formData.append('paymentStatus', this.paymentStatus)
          formData.append('agentName', this.agentName)
          formData.append('agentCode', this.agentCode)
          formData.append('agentContact', this.agentContact)
          formData.append('typeOfCall', this.typeOfCall)
          formData.append('natureOfCall', this.natureOfCall)
          formData.append('callRemarks', this.callRemarks)
          formData.append('dutyOfficer', this.dutyOfficer)
          formData.append('status', this.status)
          // eslint-disable-next-line no-plusplus
          formData.append('serviceRequests', JSON.stringify(this.serviceRequests))
          formData.append('removedRequest', JSON.stringify(this.removedRequest))
          // formData.append('serviceDescription', JSON.stringify(this.serviceDescription))
          formData.append('attachments', JSON.stringify(this.attachments))

          this.$http.patch(`operation/service-forms/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                if (option && option === 'edited' && submit) {
                  this.submitSignedForm('edited')
                } else {
                  this.$swal({
                    title: 'Service Form Updated',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: true,
                    cancelButtonText: 'View Entry',
                    allowOutsideClick: false,
                    confirmButtonText: 'Return to Service Form',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      this.savedData = true
                      if (result.value) {
                        this.$router.push({ name: 'operation-service-forms-index', params: { type: 'all-service-forms' } })
                      } else {
                        this.$router.push({ name: 'operation-service-forms-show', params: { id: response.data.data._id } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  } else if (validationError.param === 'customerEmail') {
                    this.customerEmailError = validationError.msg
                    this.customerEmailValidation = true
                  } else if (validationError.param === 'startDate') {
                    this.startError = validationError.msg
                    this.startValidation = true
                  } else if (validationError.param === 'startTime') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                  } else if (validationError.param === 'activityType') {
                    this.activityTypeError = validationError.msg
                    this.activityTypeValidation = true
                  } else if (validationError.param === 'sourceOfBooking') {
                    this.sourceOfBookingError = validationError.msg
                    this.sourceOfBookingValidation = true
                  } else if (validationError.param === 'paymentRemarks') {
                    this.paymentRemarksError = validationError.msg
                    this.paymentRemarksValidation = true
                  } else if (validationError.param === 'paymentStatus') {
                    this.paymentStatusError = validationError.msg
                    this.paymentStatusValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  } else if (validationError.param === 'eventID') {
                    this.eventIDError = validationError.msg
                    this.eventIDValidation = true
                  } else if (validationError.param === 'packageID') {
                    this.packageIDError = validationError.msg
                    this.packageIDValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
  .sd-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
</style>
